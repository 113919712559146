<template>
    <SmartTable url="/settings/comitati-stati/index" base_path="/settings/comitati-stati"></SmartTable>
</template>
<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Stati Comitati", route: "/settings/comitati-stati/index" },
             { title: "Ricerca Stati Comitati" }
         ]);
    },
 };
</script>
